<template>
  <div>
    <div class="contain1">
      <div class="contain1_1">
        <img src="@/assets/image/icon29.png" alt="" class="leftimg" />
        <div class="header_search">
          <div style="display: flex; align-items: center">
            <img src="@/assets/image/icon2.png" alt="" />
            <el-input v-model="keyword" placeholder="请输入关键字" style="width: 100%;"></el-input>
          </div>
          <div style="display: flex; align-items: center">
            <div class="search_line"></div>
            <div class="search_text" @click="search()">搜索文档</div>
          </div>
        </div>
      </div>
      <div class="contain1_2" style="margin-bottom: 20px">
        <div class="left">
          <img src="@/assets/image/icon26.png" alt="" />
          <div>文档类型</div>
        </div>
        <div class="nav">
          <div class="nav_item" :class="{ nav_item_active: item.key == currentType }" v-for="(item, index) in type"
            :key="index" @click="changeType(item)">
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="contain1_2">
        <div class="left">
          <img src="@/assets/image/icon27.png" alt="" />
          <div>文档格式</div>
        </div>
        <div class="nav">
          <div class="nav_item" :class="{ nav_item_active: item.key == currentFormat }" v-for="(item, index) in format"
            :key="index" @click="changeFormat(item)">
            <div>{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 广告位 -->
    <div class="ad" @click="link_web">
      <img :src="$imgURL + adv.image" alt="" />
    </div>
    <!-- 文档列表 -->
    <div class="contain2">
      <div class="screen_contain">
        <div class="screen_item" :class="{ screen_item_active: current == item.key }" v-for="(item, index) in screen"
          :key="index" @click="changeScreen(item)">
          <div>{{ item.name }}</div>
          <div class="line"></div>
        </div>
      </div>
      <div class="contain2_line"></div>
      <div class="list_item" v-for="(item, index) in list" :key="index" @click="goDocDetail(item)">
        <div class="item_left">
          <img v-if="item.attachment" :src="item.attachment.hash
              ? `${$imgURL + '/view/cover/' + item.attachment.hash}`
              : icon41
              " />
        </div>
        <div class="item_right">
          <div class="item_right_title">
            {{ item.title }}
          </div>
          <div class="item_right_info">
            {{ item.description }}
          </div>
          <div class="item_right_tiem">
            <div>
              {{ item.created_at.split("T")[0] }}&nbsp;{{
              item.created_at.split("T")[1].split("Z")[0]
            }}
            </div>
            <!-- <div><img src="@/assets/image/icon22.png" alt="" /></div> -->
          </div>
          <!-- <div class="item_right_line"></div> -->
        </div>
      </div>
      <div class="pagination_contain">
        <div>共{{ total || 0 }}项数据</div>
        <el-pagination background layout="prev, pager, next" :current-page="page" :page-size="size" :total="total"
          @current-change="search">
        </el-pagination>
      </div>
      <div style="height: 50px"></div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import { get, postJSON } from "/utils/request";
export default {
  data() {
    return {
      breadcrumb: [
        { id: "", name: "首页" },
        { id: 2, name: "一级分类" },
        { id: 3, name: "二级分类" },
      ],
      type: [
        { id: 1, name: "全部", key: "" },
        { id: 2, name: "VIP文档", key: "vip" },
        { id: 3, name: "付费文档", key: "charge" },
        { id: 4, name: "免费文档", key: "free" }, //积分文档就是普通文档
      ], //文档类型
      format: [
        { id: 1, name: "全部", key: "" },
        { id: 2, name: "PDF", key: "pdf" },
        { id: 3, name: "DOC", key: "doc" },
        { id: 4, name: "PPT", key: "ppt" },
        { id: 5, name: "XLS", key: "xls" },
        { id: 6, name: "TXT", key: "txt" },
        { id: 7, name: "JPG", key: "jpg" },
        { id: 8, name: "PNG", key: "png" },
        { id: 9, name: "GIF", key: "gif" },
        { id: 10, name: "其他", key: "other" },
      ], //文档格式
      currentType: "",
      currentFormat: "",
      current: "default",
      screen: [
        { id: 1, name: "推荐文章", key: "default" },
        { id: 2, name: "最新上传", key: "latest" },
      ],
      page: 1,
      size: 10,
      total: 0, //总条数
      list: [], //文档列表
      keyword: '',
      adv: {},
    };
  },
  created() {
    this.keyword = this.$route.query.keyword
    setTimeout(() => {
      this.search();

    }, 300)
    this.getAdv();
  },
  methods: {
    getAdv() {
      postJSON("/adv", { adv_id: 2 }).then((res) => {
        console.log(res);
        this.adv = res.data ?? {};
      });
    },
    search(val) {
      if (!this.keyword) {
        this.$message.warning("请输入搜索关键字");
        return;
      }
      this.page = val;
      get("/document/search", {
        page: this.page,
        size: this.size,
        sort: this.current,
        ext: this.currentFormat,
        fee_type: this.currentType,
        wd: this.keyword,
      }).then((res) => {
        console.log(res);
        this.total = res.total;
        this.list = res.document;
      });
    },
    // search(val) {
    //   if (!this.keyword) {
    //     this.$message.warning("请输入搜索关键字");
    //     return;
    //   }
    //   this.page = val;
    //   get("/document/search", {
    //     page: this.page,
    //     size: this.size,
    //     sort: this.current,
    //     ext: this.currentFormat,
    //     fee_type: this.currentType,
    //     wd: this.keyword,
    //   }).then((res) => {
    //     console.log(res);
    //     this.total = res.total;
    //     this.list = res.document;
    //   });
    // },
    goPage(item) {
      console.log(item);
      if (item.name == "首页") {
        this.$router.replace("/");
        return;
      }
    },
    changeType(item) {
      console.log(item);
      this.currentType = item.key;
      this.page = 1;
      this.list = [];
      this.search();
    },
    changeFormat(item) {
      console.log(item);
      this.currentFormat = item.key;
      this.page = 1;
      this.list = [];
      this.search();
    },
    changeScreen(item) {
      this.current = item.key;
      this.page = 1;
      this.list = [];
      this.search();
    },
    link_web() {
      window.location.href = this.adv.url;
    },
    goDocDetail(item) {
      // https://moredoc.mnt.ltd/api/v1/document?id=0&uuid=a2de7514ab3dde6f&with_author=true
      console.log(item);
      window.open( this.$router.resolve({ path: `/docDetail?id=${item.id}` }).href, '_blank');

      // this.$router.push({
      //   name: "docDetail",
      //   query: { id: item.id, uuid: item.uuid },
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.contain1 {
  width: 1400px;
  min-height: 148px;
  background: #ffffff;
  border-radius: 6px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;

  .contain1_1 {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .leftimg {
      width: 104px;
      height: 53px;
    }

    .header_search {
      width: 696px;
      height: 52px;
      background: #ffffff;
      border: 1px solid #d6d6d6;
      border-radius: 6px;
      padding: 0 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 18px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }

      ::v-deep .el-input {
        width: 500px !important;
        // border: none !important;
        // padding-left: 0;
      }
      ::v-deep .el-input__inner {
        border: none !important;
        padding-left: 0;
      }

      .search_line {
        width: 1px;
        height: 25px;
        background: #efefef;
      }

      .search_text {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        color: #23dc5a;
        margin-left: 16px;
        cursor: pointer;
      }
    }
  }

  .contain1_2 {
    display: flex;
    align-items: center;

    .left {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      color: #999999;
      margin-right: 50px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 6px;
      }
    }

    .nav {
      display: flex;
      align-items: center;

      .nav_item {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        margin-right: 30px;
        cursor: pointer;
      }

      .nav_item_active {
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #23dc5a;
        margin-right: 30px;
        cursor: pointer;
      }
    }
  }
}

.ad {
  width: 1400px;
  height: 88px;
  margin: 20px auto;
  border-radius: 6px;

  img {
    width: 1400px;
    height: 88px;
    border-radius: 6px;
  }
}

.contain2 {
  width: 1400px;
  // height: 2357px;
  background: #ffffff;
  border-radius: 6px;
  margin: 0 auto;
  padding: 20px 20px 0;
  box-sizing: border-box;

  .screen_contain {
    display: flex;
    align-items: center;

    .screen_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;

      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #666666;
        margin-bottom: 13px;
      }

      .line {
        width: 72px;
        height: 3px;
        background: transparent;
      }
    }

    .screen_item_active {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 80px;
      cursor: pointer;

      div:nth-child(1) {
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        color: #23dc5a;
        margin-bottom: 13px;
      }

      .line {
        width: 72px;
        height: 3px;
        background: #23dc5a;
      }
    }
  }

  .contain2_line {
    height: 1px;
    background: #f6f7f8;
    margin-bottom: 20px;
  }

  .list_item {
    display: flex;
    margin-bottom: 24px;
    // justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #f6f7f8;
    padding-bottom: 24px;

    .item_left,
    img {
      // width: 414px;
      // height: 175px;
      width: 114px;
        height: 160px;
        border: 1px solid #c6c6c6;
        border-radius: 6px;
        margin-right: 20px;
    }

    .item_right {
      width: 1226px;
      // min-height: 200px;
      .item_right_title {
        width: 926px;
        height: 25px;
        font-size: 18px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: left;
        color: #333333;
        letter-spacing: 0.9px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .item_right_info {
        max-height: 44px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #666666;
        letter-spacing: 0.63px;
        margin: 20px 0 31px;
        width: 1226px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .item_right_tiem {
        display: flex;
        align-items: center;
        justify-content: space-between;

        div:nth-child(1) {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #999999;
          letter-spacing: 0.63px;
        }

        div:nth-child(2) {
          img {
            width: 20px;
            height: 20px;
          }
        }
      }

      .item_right_line {
        width: 926px;
        height: 1px;
        background: #f4f4f4;
        margin: 45px 0 15px 0;
      }
    }
  }

  .pagination_contain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 36px;

    ::v-deep .el-pager .active {
      background: #23dc5a !important;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }

    ::v-deep .el-pager .number {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 46px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quicknext {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .el-pager .btn-quickprev {
      background: #f2f2f3;
      width: 47px;
      height: 46px;
      border-radius: 3px;
      line-height: 60px;
      font-size: 20px;
    }

    ::v-deep .btn-prev {
      width: 46px;
      height: 46px;

      .el-icon-arrow-left {
        font-size: 24px;
      }
    }

    ::v-deep .btn-next {
      width: 46px;
      height: 46px;

      .el-icon-arrow-right {
        font-size: 24px;
      }
    }

    div:nth-child(1) {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #666666;
      letter-spacing: 0.63px;
    }
  }
}
</style>